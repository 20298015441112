import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  notificationsAreaDeregistered,
  notificationsAreaRegistered,
  notificationsDeleted,
  notificationsMarkedRead,
} from '~/state/notifications/actions';
import {
  createSelectNotificationsByArea,
  createSelectUnreadNotificationsCount,
} from '~/state/notifications/selectors';

import { AreaConfig, NotificationEvent } from './types';

export function useNotificationSubscribe(options: AreaConfig) {
  const dispatch = useDispatch();

  return useEffect(() => {
    dispatch(notificationsAreaRegistered(options));

    return () => {
      dispatch(notificationsAreaDeregistered(options.id));
    };
  }, [dispatch, options]);
}

export function useNotificationArea(
  config: AreaConfig
): { notifications: NotificationEvent[]; unreadCount: number } {
  const { id } = config;
  const areaSelector = useMemo(() => createSelectNotificationsByArea(id), [id]);
  const selectUnreadCount = createSelectUnreadNotificationsCount(areaSelector);

  const selector = createStructuredSelector({
    notifications: areaSelector,
    unreadCount: selectUnreadCount,
  });
  return useSelector(selector);
}

export function useNotificationInteractions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      notificationsDeleted: (ids: string[]) =>
        dispatch(notificationsDeleted(ids)),
      notificationsMarkedRead: (ids: string[]) =>
        dispatch(notificationsMarkedRead(ids)),
    }),
    [dispatch]
  );
  return actions;
}

export function useNotifications(options: AreaConfig) {
  useNotificationSubscribe(options);
  const data = useNotificationArea(options);
  const actions = useNotificationInteractions();
  return { data, actions };
}
