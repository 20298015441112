import {
  AnonHeaderVariant,
  AppHeaderClickHandler,
  GlobalHeaderProps,
} from '@codecademy/brand';
import { useSelector } from 'react-redux';

import { ccdata } from '~/libs/ccdata';
import { isChromeOSPWA } from '~/libs/device';
import { trackUserClick } from '~/libs/eventTracking';
import { createRedirectParam } from '~/libs/url';
import { proCheckoutTrialPath } from '~/libs/urlHelpers';
import {
  selectCanAccessCareerPaths,
  selectCurrentUser,
  selectCurrentUserDisplayName,
  selectEnterpriseUserUrl,
  selectHideBusinessAccount,
  selectInBusinessTrial,
  selectIsAccountManager,
  selectIsAdmin,
  selectIsBusinessAdmin,
  selectIsBusinessSsoUser,
  selectIsBusinessUser,
  selectIsCustomerService,
  selectIsEnterpriseUser,
  selectIsLoggedIn,
  selectIsPro,
  selectIsProPaused,
  selectIsProSilver,
  selectShowMoneybackCTA,
  selectShowTrialCTA,
} from '~/state/currentUser/selectors';
import { useFeatureFlag } from '~/state/features/hooks';
import { useClientsideExperiment } from '~/state/hooks/useClientsideExperiment';
import { selectLocation } from '~/state/location/selectors';
import { Experiments } from '~/typings/experiment';

import { appHeaderSearch } from './search';
import { useAppHeaderNotifications } from './useAppHeaderNotifications';

export const useAppHeaderProps = (
  enterprise?: boolean,
  simple?: boolean
): GlobalHeaderProps => {
  const isAccountManager = useSelector(selectIsAccountManager);
  const isAdmin = useSelector(selectIsAdmin);
  const isCustomerService = useSelector(selectIsCustomerService);
  const isBusinessAdmin = useSelector(selectIsBusinessAdmin);
  const isBusinessSsoUser = useSelector(selectIsBusinessSsoUser);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const isEnterpriseUrl = useSelector(selectEnterpriseUserUrl);
  const hideBusinessAccount = useSelector(selectHideBusinessAccount);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isPro = useSelector(selectIsPro);
  const isPlusUser = useSelector(selectIsProSilver);
  const isProPaused = useSelector(selectIsProPaused);
  const isBusinessUser = useSelector(selectIsBusinessUser);
  const inBusinessTrial = useSelector(selectInBusinessTrial);
  const location = useSelector(selectLocation);
  const notifications = useAppHeaderNotifications();

  const showTrialCTA = useSelector(selectShowTrialCTA);
  const showMoneybackCTA = useSelector(selectShowMoneybackCTA);
  const userAvatar = useSelector(selectCurrentUser)?.profileImage;
  const userDisplayName = useSelector(selectCurrentUserDisplayName);
  const hideCareerPaths = !useSelector(selectCanAccessCareerPaths);

  const isEnterpriseGlobalNavEnabled = useFeatureFlag('enterprise_global_nav');

  const mbgExperiment = useClientsideExperiment(
    Experiments.MoneybackGuarantee,
    !isPro
  );

  const teamsRevampEnabled = useFeatureFlag('cycle_e_teams_revamp');

  const unifiedRecFlowV2Enabled = useFeatureFlag('cycle_e_unified_rec_flow_v2');

  const handleClick: AppHeaderClickHandler = (event, item) => {
    if (item.type !== 'render-element') {
      trackUserClick({
        context: 'global_nav',
        target: item.trackingTarget,
      });
    }
  };

  const getAnonVariant = (): AnonHeaderVariant | undefined => {
    if (location?.pathname.includes('login')) return 'login';
    if (location?.pathname.includes('register')) return 'signup';
    if (location?.pathname === '/') return 'landing';
  };
  const anonVariant = getAnonVariant();

  const isEnterpriseVariant =
    isEnterpriseUser && isEnterpriseGlobalNavEnabled && enterprise && !isAdmin;

  const redirectPath = __SSR__
    ? ccdata.get('request_path')
    : window.location.href;

  const pwaMode = isChromeOSPWA();

  const enterpriseUrl = (): string | undefined => {
    if (!isEnterpriseUrl) return undefined;

    const protocol = 'https://';

    if (isEnterpriseUrl.includes(protocol)) return isEnterpriseUrl;

    return new URL(protocol + isEnterpriseUrl).toString();
  };

  const showProUpgrade =
    !showTrialCTA ||
    pwaMode ||
    (Boolean(showMoneybackCTA) &&
      mbgExperiment.experimentAssignment === 'variant');

  if (mbgExperiment.loading) {
    return {
      action: handleClick,
      search: {
        onEnable: () => null,
        onSearch: () => null,
        onTrackingClick: () => null,
        onSearchAsYouType: () => null,
      },
      type: 'loading',
    };
  }

  if (simple) {
    return {
      action: handleClick,
      search: {
        onEnable: () => null,
        onSearch: () => null,
        onTrackingClick: () => null,
        onSearchAsYouType: () => null,
      },
      type: 'simple',
    };
  }

  return {
    action: handleClick,
    hidePricing: pwaMode,
    search: appHeaderSearch,
    ...(isLoggedIn
      ? {
          ...(isEnterpriseVariant
            ? {
                type: 'enterprise',
                user: {
                  avatar: userAvatar,
                  displayName: userDisplayName,
                  isAccountManager,
                  isAdmin,
                  isBusinessAdmin,
                  isBusinessSsoUser,
                  hideBusinessAccount,
                  hideCareerPaths,
                  isCustomerSupport: isCustomerService,
                  isPaused: isProPaused,
                  enterpriseUrl: enterpriseUrl(),
                },
              }
            : {
                notifications,
                type: isBusinessUser ? 'teams' : isPro ? 'pro' : 'free',
                user: {
                  avatar: userAvatar,
                  displayName: userDisplayName,
                  isAccountManager,
                  isAdmin,
                  isBusinessAdmin,
                  isBusinessSsoUser,
                  hideBusinessAccount,
                  hideCareerPaths,
                  isPlusUser,
                  isCustomerSupport: isCustomerService,
                  isPaused: isProPaused,
                  showProUpgrade,
                  showMoneybackCTA,
                  proCheckoutUrl: proCheckoutTrialPath({
                    redirectUrl: createRedirectParam(location),
                  }),
                  isBusinessUser,
                  inBusinessTrial,
                },
                isInMoneyBackGuaranteeVariant:
                  mbgExperiment.experimentAssignment === 'variant',
                teamsRevampEnabled,
                unifiedRecFlowV2Enabled,
              }),
        }
      : {
          type: 'anon',
          variant: anonVariant,
          redirectParam: redirectPath,
          user: {
            avatar: '',
            displayName: '',
            hideCareerPaths,
          },
          teamsRevampEnabled,
          unifiedRecFlowV2Enabled,
        }),
  };
};
