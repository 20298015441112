import { AppHeaderSearch } from '@codecademy/brand';

import { trackEvent, trackUserClick } from '~/libs/eventTracking';
import { searchPath } from '~/libs/urlHelpers';

export const appHeaderSearch: AppHeaderSearch = {
  onEnable: () => {
    trackUserClick({
      context: 'global_nav',
      target: 'topnav_search',
    });
  },
  onSearch: (query, fromPrevSearch) => {
    window.location.assign(searchPath(query, fromPrevSearch));
  },
  onTrackingClick: (target, extraTrackingProps) => {
    trackUserClick({
      context: 'searchbar',
      target,
      ...(extraTrackingProps ?? {}),
    });
  },
  onSearchAsYouType: (
    query: string,
    searchId: string,
    resultsCount: number,
    queryLoadTime: number
  ) => {
    trackEvent('search', 'query', {
      search_id: searchId,
      search_term: query,
      total_results: resultsCount,
      codecademy_env: process.env.NEXT_PUBLIC_ENV,
      page_name: 'platform',
      query_load_time: queryLoadTime,
      misc: JSON.stringify({ type: 'search-as-you-type' }),
    });
  },
};
