import { Dictionary } from '~/typings/globals';

// Entities
export enum NotificationCampaignType {
  achievements = 'achievements',
  local = 'local',
  streaks = 'streaks',
}

export interface NotificationImage {
  slug: string;
  type: string;
}

export type NotificationType =
  | 'achievement'
  | 'target_not_met'
  | 'target_met'
  | 'target_exceeded'
  | 'streak_extended'
  | 'streak_started'
  | 'marketing_blast'
  | 'curriculum_blast'
  | 'forum_comment'
  | 'forum_like'
  | 'forum_message';

export interface NotificationEvent {
  id: string;
  name?: string;
  description: string;
  img: NotificationImage;
  href: string;
  /* 0 is read, 1 is unread */
  status: 0 | 1;
  time: string;
  type: NotificationType;
  campaign_name: NotificationCampaignType;
}

export type NotificationAreaId = 'bell' | 'toast';

export interface AreaConfig {
  id: NotificationAreaId;
  /* the types of notifications to show in the area. empty array shows all types. */
  types?: (NotificationType | NotificationCampaignType)[];
  /* whether to show read notifications in the area */
  showRead?: boolean;
  /* the number of notifications to show in the area */
  limit?: number;
  /* the length of time to show in the area, in seconds */
  timeframe?: number;
}

export const NotificationAreas: Record<string, AreaConfig> = {
  HEADER_AREA: {
    id: 'bell',
    types: [],
    showRead: true,
  },
  TOAST_AREA: {
    id: 'toast',
    types: [
      NotificationCampaignType.achievements,
      NotificationCampaignType.streaks,
    ],
    showRead: false,
    limit: 3,
    timeframe: 30 * 60,
  },
};

// Action Shapes
export type FetchSuccessPayload = NotificationEvent[];
export type MarkReadPayload = string[];
export type DeletePayload = string[];
export type RegisterPayload = AreaConfig;
export type DeregisterPayload = NotificationAreaId;

// Reducer
export type NotificationsPayload =
  | DeletePayload
  | MarkReadPayload
  | RegisterPayload
  | DeregisterPayload
  | FetchSuccessPayload
  | void;

export type AreaShape = Dictionary<AreaConfig, NotificationAreaId>;

export interface EntitiesShape {
  [key: string]: NotificationEvent;
}

export interface NotificationsState {
  areas: AreaShape;
  entities: EntitiesShape;
  polling: boolean;
  baseUrl: string;
}
