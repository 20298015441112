import { Notification, NotificationListProps } from '@codecademy/brand';
import { colors } from '@codecademy/gamut-styles';
import { formatDistanceToNow } from 'date-fns';

import { NotificationEvent } from '~/state/notifications/types';

const byDateComparator = (
  eventA: NotificationEvent,
  eventB: NotificationEvent
) => new Date(eventB.time).getTime() - new Date(eventA.time).getTime();

export const getSortedNotifications = (
  notifications: NotificationEvent[]
): NotificationListProps['notifications'] => {
  return notifications.sort(byDateComparator).map(
    (event): Notification => {
      return {
        date: formatDistanceToNow(new Date(event.time)),
        id: event.id,
        iconSettings: { color: colors['blue-900'] },
        link: event.href,
        text: event.description,
        unread: event.status === 1,
        campaign: event.campaign_name,
        imageUrl: event.img?.type === 'url' ? event.img.slug : '',
        type: event.type,
      };
    }
  );
};
