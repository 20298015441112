import { AppHeaderNotificationSettings } from '@codecademy/brand';
import { useSelector } from 'react-redux';

import { track, trackUserClick } from '~/libs/eventTracking';
import { selectLocationType } from '~/state/location/selectors';
import { useNotifications } from '~/state/notifications/hooks';
import { NotificationAreas } from '~/state/notifications/types';

import { getSortedNotifications } from './getSortedNotifications';

export const useAppHeaderNotifications = (): AppHeaderNotificationSettings => {
  const {
    actions,
    data: { notifications },
  } = useNotifications(NotificationAreas.HEADER_AREA);
  const locationType = useSelector(selectLocationType);

  return {
    actions: {
      clear: () => {
        actions.notificationsDeleted(
          notifications.map((notification) => notification.id)
        );
      },
      click: (notification) => {
        // Tracking for Iterable campaigns - as per conversation w/ Marketing
        track('notification', 'clicked', {
          target: 'notification_bell_cta',
          page_name: locationType,
          event_id: notification.id,
          context: notification.campaign,
          element: notification.type,
        });

        // Tracking for user clicks
        trackUserClick({
          target: 'notification_bell_cta',
          page_name: locationType,
          context: 'topnav_bell',
          slug: notification.campaign,
          element: notification.type,
        });
      },
      dismiss: (notification) => {
        actions.notificationsDeleted([notification.id]);
      },
      read: (notifications) => {
        actions.notificationsMarkedRead(notifications.map(({ id }) => id));
      },
      track: (target) => {
        trackUserClick({ target, page_name: locationType });
      },
    },
    notifications: getSortedNotifications(notifications),
    onEnable: () => {
      trackUserClick({
        context: 'global_nav',
        target: 'notification_bell',
      });
    },
  };
};
